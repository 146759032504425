import {AxiosInstance} from 'axios'
import {isArray} from 'lodash'

import {useFeaturesCheckFetcher} from './useFeaturesCheckFetcher'

type Name = string | string[] | null

export const useFeaturesChecker = (api: AxiosInstance, name?: Name) => {
  const {data} = useFeaturesCheckFetcher(api)

  const calcEnabled = (extraName?: Name) => {
    if (!extraName) {
      return true
    }

    return (
      data?.some((feature) => {
        if (isArray(extraName)) {
          if (!extraName.includes(feature.name)) return false

          return feature.enabled ?? false
        }

        if (extraName !== feature.name) {
          return false
        }

        return feature.enabled ?? false
      }) ?? false
    )
  }

  return {
    enabled: calcEnabled(name),
    calcEnabled
  }
}
