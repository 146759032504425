import {FeaturesFilter} from '@hconnect/adminconsole/src/pages/FeaturesList/FeaturesList'
import {AxiosError, AxiosInstance, AxiosResponse} from 'axios'
import {QueryFunction, useQuery} from 'react-query'

export interface Feature {
  name: string
  enabled?: boolean
  description: string
  tags: string[]
  constraints: FeatureConstraint[]
  type?: string
}
export type FeatureConstraintType = 'user' | 'role' | 'data' | 'public'

export interface FeatureConstraint {
  type?: FeatureConstraintType
  dataScope: DataScope | string
  enabled: boolean
  country: string
}

export interface DataScope {
  countryId?: BrandingCountries | BrandingCountries[]
  orgUnitId?: string
  businessLine?: BusinessLineTypes
}

export enum BusinessLineType {
  CEM = 'CEM',
  RMC = 'RMC',
  AGG = 'AGG'
}
export type BusinessLineTypes = keyof typeof BusinessLineType

export enum BrandingCountry {
  AU = 'AU',
  CA = 'CA',
  HK = 'HK',
  RU = 'RU',
  US = 'US'
}

type FeaturesListParams = {
  skip?: number
  limit?: number
  name?: string
  description?: string
  tags?: string
  type?: string
  enabled?: boolean
  rowsPerPage?: number
  page?: number
  sortColumn?: string
  sortDirection?: string
  country: string
}

export type BrandingCountries = keyof typeof BrandingCountry

type QueryKey = [string, AxiosInstance]

type FeaturesListQueryKey = [string, AxiosInstance, string | undefined, {filter: FeaturesFilter}]

const featuresCheckFetcher: QueryFunction<Feature[]> = async ({queryKey}) => {
  const [endpoint, api] = queryKey as QueryKey
  try {
    const response = await api.get<Feature[]>(`/${endpoint}?tag=hub`)
    return response.data
  } catch (e) {
    console.error(e)
    return []
  }
}

const featuresListFetcher: QueryFunction<AxiosResponse<Feature[]> | null> = async ({queryKey}) => {
  const [endpoint, api, tag, {filter}] = queryKey as FeaturesListQueryKey
  const params: FeaturesListParams = {
    rowsPerPage: filter.rowsPerPage,
    page: filter.page,
    sortColumn: filter.sortedByKey,
    sortDirection: filter.sortedByDirection,
    skip: (filter.page - 1) * filter.rowsPerPage,
    limit: filter.rowsPerPage,
    name: filter.name,
    description: filter.description,
    tags: filter.tags,
    type: filter.type,
    enabled: filter.enabled,
    country: filter.country
  }

  try {
    return await api.get<Feature[]>(`/${endpoint}${tag !== 'all' ? `?tag=${tag}` : ''}`, {
      params
    })
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const useFeaturesCheckFetcher = (api: AxiosInstance) => {
  return useQuery<Feature[], AxiosError, Feature[], QueryKey>(
    ['features', api],
    featuresCheckFetcher,
    {
      refetchOnWindowFocus: true,
      retry: false
    }
  )
}

export const useFeaturesListFetcher = (api: AxiosInstance, tag = 'hub', filter: FeaturesFilter) => {
  return useQuery<
    AxiosResponse<Feature[]> | null,
    AxiosError,
    AxiosResponse<Feature[]>,
    FeaturesListQueryKey
  >(['features/paginatedFeatures', api, tag, {filter}], featuresListFetcher, {
    cacheTime: 0,
    refetchOnWindowFocus: true,
    retry: false
  })
}
