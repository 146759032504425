export const hproduce = {
  name: 'HProduce',
  url: {
    qa: 'https://qa.hproduce.digital',
    uat: 'https://uat.hproduce.digital',
    prod: 'https://hproduce.digital'
  }
}

// Route for plant performance page
// redirected from the header
export const PLANT_KPI = '/performance/plant/:plantId'

export const MY_TASKS = '/myTasks'
