import {PermissionType} from '@hconnect/apiclient'

export type PlantId = string

export type MaintainAppName = 'Rcfa' | 'KMS' | 'Maintain' | 'default'

export type MaintainAppConfig = {
  contact?: {
    name: string
    mail: string
    profileImage: string
  }
  name: MaintainAppName
  displayName: string

  urlAlias: string
  whiteListedPermissionTypes: PermissionType[]
  plant?: {
    whiteList?: PlantId[]
    blackList?: PlantId[]
  }
}

export type MaintainAppOption = {
  appName: MaintainAppName
  plantId: PlantId
  plantName: string
}

export const kms: MaintainAppConfig = {
  name: 'KMS',
  displayName: 'Kiln monitoring',
  urlAlias: 'kms',
  whiteListedPermissionTypes: ['VIEW_KILN_MONITORING_DATA'],
  plant: {
    whiteList: ['0014']
  }
}

export const rcfa: MaintainAppConfig = {
  name: 'Maintain',
  displayName: 'RCFA',
  urlAlias: 'rcfa',
  whiteListedPermissionTypes: ['CONTRIBUTE_TO_ROOT_CAUSE_FAILURE_ANALYSES']
}

export const maintainApps: Partial<Record<MaintainAppName, MaintainAppConfig>> = {
  Maintain: rcfa,
  KMS: kms
}

export const maintainAllApps = Object.values(maintainApps)
