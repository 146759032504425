import cockpitContactPersonImageSrc from '../assets/ContactPersonImage/cockpit.jpeg'
import {DASHBOARD_SEGMENT, PLANT_ID} from '../consts'
import {AppConfig} from '../types'

export const EASY_POM_HOST = {
  qa: 'https://qa.pom.heidelbergcement.com',
  uat: 'https://uat.pom.heidelbergcement.com',
  prod: 'https://pom.heidelbergcement.com'
}

export const easyPOM: AppConfig = {
  contact: {
    name: 'Gesa Hartmann',
    mail: 'gesa.hartmann@heidelbergmaterials.com',
    profileImage: cockpitContactPersonImageSrc
  },
  name: 'easyPOM',
  url: {
    qa: `${EASY_POM_HOST.qa}/${PLANT_ID}/${DASHBOARD_SEGMENT}`,
    uat: `${EASY_POM_HOST.uat}/${PLANT_ID}/${DASHBOARD_SEGMENT}`,
    prod: `${EASY_POM_HOST.prod}/${PLANT_ID}/${DASHBOARD_SEGMENT}`
  },

  whiteListedPermissionTypes: ['VIEW_POM_DASHBOARD']
}
