import plannerContactPersonImageSrc from '../assets/ContactPersonImage/planner.jpeg'
import {PLANT_ID} from '../consts'
import plants from '../plants.json'
import {AppConfig, PlantId} from '../types'

// allowing plants from the list only
const whiteListedPlants: PlantId[] = Object.keys(plants)

export const settings: AppConfig = {
  contact: {
    name: 'Daniil Starikov',
    mail: 'daniil.starikov@heidelbergmaterials.com',
    profileImage: plannerContactPersonImageSrc
  },
  name: 'Settings',
  url: {
    qa: `https://settings-qa.hproduce.digital/plants/${PLANT_ID}`,
    uat: `https://settings-uat.hproduce.digital/plants/${PLANT_ID}`,
    prod: `https://settings.hproduce.digital/plants/${PLANT_ID}`
  },
  whiteListedPermissionTypes: ['VIEW_ELECTRICITY_PRICE', 'VIEW_MATERIAL_STORAGE', 'VIEW_UPM'],
  plant: {
    whiteList: whiteListedPlants
  }
}
