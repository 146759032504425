import maintainContactPersonImageSrc from '../assets/ContactPersonImage/maintain.jpeg'
import {PLANT_ID} from '../consts'
import {AppConfig} from '../types'

export const MAINTAIN_HOST = {
  qa: 'https://maintain-qa.hproduce.digital',
  uat: 'https://maintain-uat.hproduce.digital',
  regression: 'https://maintain-uat.hproduce.digital',
  prod: 'https://maintain.hproduce.digital'
}

export const maintain: AppConfig = {
  contact: {
    name: 'Rajendra Reddy',
    mail: 'rajendra.reddy@heidelbergmaterials.com',
    profileImage: maintainContactPersonImageSrc
  },
  name: 'Maintain',
  url: {
    qa: `${MAINTAIN_HOST.qa}/plant/${PLANT_ID}`,
    uat: `${MAINTAIN_HOST.uat}/plant/${PLANT_ID}`,
    prod: `${MAINTAIN_HOST.prod}/plant/${PLANT_ID}`
  },
  whiteListedPermissionTypes: [
    'CONTRIBUTE_TO_ROOT_CAUSE_FAILURE_ANALYSES',
    'VIEW_EQUIP_PULSE',
    'VIEW_KILN_MONITORING_DATA'
  ]
}
