type CatchPromiseReturnValue<ReturnValueType, ErrorType> = Promise<{
  data?: ReturnValueType
  err?: ErrorType
}>

/**
 * Avoid (nested) try catch statements.
 *  try{
 *    await foo()
 *    catch(fooError){
 *        try{
 *            await bar()
 *        }
 *        catch(barError){
 *            doSomething()
 *        }
 *    }
 *  }
 *
 * can be written more conveniently
 *
 * {err} = catchPromise(foo())
 * if(err) {err} = catchPromise(bar())
 * if(err) doSomething()
 *
 */
export const catchPromise = <ReturnValueType, ErrorType = unknown>(
  promise: Promise<ReturnValueType>
): CatchPromiseReturnValue<ReturnValueType, ErrorType> => {
  return promise
    .then((data) => ({data}))
    .catch((err: ErrorType) => ({
      err
    }))
}
export const catchPromise__deprecated = (promise) =>
  promise.then((data) => [null, data]).catch((err) => [err])
