import {Country} from '@hconnect/apiclient'
import {AxiosError, AxiosInstance} from 'axios'
import {QueryFunction, useQuery} from 'react-query'

import {useApi} from './useApi'

const countryConfigurationFetcher =
  (api: AxiosInstance): QueryFunction<Country | null> =>
  async ({queryKey}) => {
    const [base, endpoint, country] = queryKey

    if (!country) {
      return null
    }

    // This is public endpoint
    const response = await api.get<Country>(`/${base}/${endpoint}/${country}`)
    return response.data
  }

export const useCountryConfiguration = (country?: string | null) => {
  const {publicApi} = useApi()

  return useQuery<Country | null, AxiosError>(
    ['configurations', 'countries', country],
    countryConfigurationFetcher(publicApi),
    {
      retry: false
    }
  )
}
