import {AxiosInstance} from 'axios'
import moment from 'moment'
import {useMutation, UseMutationOptions} from 'react-query'

import {BaseVersionArgs} from './useLegalDocuments'

type QueryKey = [
  {
    username: string | null | undefined
    termsAcceptedVersion: BaseVersionArgs['tcVersion']
    dataPrivacyAcknowledgedVersion: BaseVersionArgs['dppVersion']
  }
]

const acceptLegalMutation =
  (authorizedApiClient: AxiosInstance) =>
  async ({queryKey}) => {
    const [{username, termsAcceptedVersion, dataPrivacyAcknowledgedVersion}] = queryKey as QueryKey

    if (!username || !termsAcceptedVersion || !dataPrivacyAcknowledgedVersion) {
      return null
    }

    const response = await authorizedApiClient.put(`/users/${username}`, {
      termsAcceptedVersion,
      termsAcceptedOn: moment().toISOString(),
      dataPrivacyAcknowledgedVersion,
      dataPrivacyAcknowledgedOn: moment().toISOString()
    })

    return response.data
  }

interface Args extends BaseVersionArgs {
  username?: string | null
  authorizedApiClient: AxiosInstance
  mutationOptions: UseMutationOptions<unknown, unknown, {queryKey: QueryKey}, unknown | undefined>
  tcVersion?: string | null
  dppVersion?: string | null
}

export const useLegalAcceptance = ({
  username,
  authorizedApiClient,
  mutationOptions,
  tcVersion,
  dppVersion
}: Args) => {
  const queryKey = {
    username: username,
    termsAcceptedVersion: tcVersion,
    dataPrivacyAcknowledgedVersion: dppVersion
  }

  const mutation = useMutation(
    [queryKey],
    acceptLegalMutation(authorizedApiClient),
    mutationOptions
  )

  const accept = () => {
    if (!username) {
      throw new Error('Username was not provided to useLegalAcceptance hook')
    }
    if (!tcVersion) {
      throw new Error('Version of terms and conditions was not provided to useLegalAcceptance hook')
    }
    if (!tcVersion) {
      throw new Error('Version of data privacy policy was not provided to useLegalAcceptance hook')
    }

    mutation.mutate({queryKey: [queryKey]})
  }

  return {accept, mutation}
}
