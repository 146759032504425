import {AxiosInstance} from 'axios'
import React from 'react'

import {useFeaturesChecker} from './useFeaturesChecker'

type Props = {
  api: AxiosInstance
  name: string | string[]
  children: React.ReactNode
}

export const FeaturesCheck = ({api, name, children}: Props) => {
  const {enabled} = useFeaturesChecker(api, name)

  if (!enabled) {
    return null
  }

  return <>{children}</>
}
