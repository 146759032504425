import axios from 'axios'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'

import {useCountryConfiguration} from '../useCountryConfiguration'

import type {LegalDocumentsType} from './types'
import {useLatestDocumentVersion} from './useLegalDocumentsVersions'

type QueryKey = [string, string, string | undefined, string, string?]

const legalDocumentFetcher = async ({queryKey}) => {
  const [base, type, country, locale, version] = queryKey as QueryKey

  if (!country || !version) return

  const response = await axios.get<string>(
    `${base}/generated/hconnect/${type}/${country}/${locale}/${version}.html`
  )

  return response.data
}

export type BaseVersionArgs = {
  tcVersion?: string | null
  dppVersion?: string | null
}

export const useLegalDocument = (
  documentType: LegalDocumentsType,
  country?: string | null,
  preferredLanguage?: string | null
) => {
  const [locale, setLocale] = useState(preferredLanguage)
  const url = process.env.REACT_APP_LEGAL_URL
  const [enableRetry, setEnableRetry] = useState(false)

  const countryConfiguration = useCountryConfiguration(country)
  const countryDefaultLanguage = countryConfiguration?.data?.defaultLanguage
  const latestVersionQueryInfo = useLatestDocumentVersion(documentType, country)

  const availableLanguages =
    latestVersionQueryInfo?.lastAvailableDocumentVersion?.availableLanguages
  // We need to validate if user of this hook provided valid arguments = if document exists on legal blob storage
  useEffect(() => {
    if (!countryDefaultLanguage) {
      return
    }
    const shouldUseDefaultLanguage =
      availableLanguages && !availableLanguages.includes(preferredLanguage || '')

    if (shouldUseDefaultLanguage) {
      setLocale(countryDefaultLanguage)
    } else if (locale !== preferredLanguage) {
      // In case different language has been selected and it is included in available languages
      setLocale(preferredLanguage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryDefaultLanguage, preferredLanguage, availableLanguages])

  useEffect(() => {
    if (enableRetry) {
      void queryInfo.refetch()
    }
  }, [enableRetry])

  const queryInfo = useQuery(
    [
      url,
      documentType.toLowerCase(),
      country?.toLowerCase(),
      locale?.toLowerCase(),
      latestVersionQueryInfo?.lastAvailableDocumentVersion?.version
    ],
    legalDocumentFetcher,
    {
      retry: !locale || enableRetry,
      onError: () => {
        if (!enableRetry) {
          setEnableRetry(true)
          setLocale('en')
        }
      }
    }
  )

  return {
    queryInfo,
    language: locale
  }
}
