import predictContactPersonImageSrc from '../assets/ContactPersonImage/predict.jpeg'
import {PLANT_ID} from '../consts'
import {AppConfig} from '../types'

export const predict: AppConfig = {
  contact: {
    name: 'Dr. Christoph Deil',
    mail: 'christoph.deil@heidelbergmaterials.com',
    profileImage: predictContactPersonImageSrc
  },
  name: 'Predict',
  url: {
    qa: `https://predict-qa.hproduce.digital/plants/${PLANT_ID}`,
    uat: `https://predict-uat.hproduce.digital/plants/${PLANT_ID}`,
    prod: `https://predict.hproduce.digital/plants/${PLANT_ID}`
  },
  whiteListedPermissionTypes: ['VIEW_PREDICTIONS'], // ENERGY_MANAGER is missing the VIEW_PREDICTIONS permission
  plant: {
    whiteList: [
      // Pilot
      '0014',
      'PL1A',
      // NAM
      'U175',
      'U100',
      'U145',
      'U155',
      'U157',
      'U159',
      'U160',
      'C102',
      'C203',
      'C205',
      // Archinext
      'IT17',
      'IT19',
      'IT11',
      'IT15',
      'IT1G',
      'IT1F',
      'IT12',
      'IT14',
      'IT13',
      'IT27',
      'IT28',
      'W025',
      'IT16',
      'IT10',
      'IT18'
    ]
  }
}
