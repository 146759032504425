import axios from 'axios'
import {useQuery} from 'react-query'

import type {LegalDocumentsType} from './types'

type QueryKey = [string, string]

enum CountryCode {
  au = 'au',
  ba = 'ba',
  bg = 'bg',
  us = 'us',
  ca = 'ca',
  ru = 'ru',
  cz = 'cz',
  ro = 'ro',
  ge = 'ge',
  no = 'no',
  gb = 'gb',
  de = 'de',
  be = 'be',
  nl = 'nl',
  es = 'es',
  hk = 'hk',
  kz = 'kz',
  pl = 'pl',
  mock = 'mock'
}

export type VersionEntry = {
  version: string
  availableLanguages: string[]
  validFrom: string
}

type Versions = {
  hconnect: {
    dpp: {
      [key in keyof typeof CountryCode | string]?: VersionEntry[]
    }
    tc: {
      [key in keyof typeof CountryCode | string]?: VersionEntry[]
    }
  }
}

const versionsFetcher = async ({queryKey}) => {
  const [base, versionsFile] = queryKey as QueryKey

  const response = await axios.get<Versions>(`${base}/generated/${versionsFile}`)

  return response.data
}

export const useLegalDocumentsVersions = () => {
  return useQuery([process.env.REACT_APP_LEGAL_URL, 'versions.json'], versionsFetcher)
}

const getNewestValidVersion = (prev: VersionEntry, current: VersionEntry) => {
  if (new Date(current.validFrom).getTime() > new Date().getTime()) {
    return prev
  }

  return Number(prev.version) > Number(current.version) ? prev : current
}

export const useLatestDocumentVersion = (
  documentType: LegalDocumentsType,
  country?: string | null
) => {
  const {data, isLoading} = useLegalDocumentsVersions()

  if (!country) {
    return null
  }

  const documentMetadata = data?.hconnect[documentType]?.[country?.toLowerCase()]

  return {lastAvailableDocumentVersion: documentMetadata?.reduce(getNewestValidVersion), isLoading}
}

export const useLegalDocumentAcceptanceRequired = (
  documentType: LegalDocumentsType,
  country?: string | null,
  lastAcceptedVersion?: string
) => {
  const latestDocumentsVersionQueryInfo = useLatestDocumentVersion(documentType, country)

  if (lastAcceptedVersion === null) {
    return true
  }

  if (
    lastAcceptedVersion === undefined ||
    !latestDocumentsVersionQueryInfo ||
    !latestDocumentsVersionQueryInfo.lastAvailableDocumentVersion?.version
  ) {
    return false
  }

  return (
    Number(latestDocumentsVersionQueryInfo.lastAvailableDocumentVersion.version) >
    Number(lastAcceptedVersion)
  )
}
